import { ReactElement } from 'react';
import { Action } from 'redux';
import { CommandType, ProviderSubmissionChannel, StatusEnum, ToastLocationEnum, ToastType } from './enums';
import { IManualDownloadLinkProps } from 'components/ContinuousImprovement/ManualDownladProviderFileLink';
import { ACTIVITY_TYPE_ABBREVIATION_ENUM, TAGS_ENUM } from './constants';
import { IInitialSearchFacet } from 'components/ContinuousImprovement/Facets/types';
import { ITypeaheadOption } from 'components/ContinuousImprovement/Typeahead';
import { BoardIntegrationTypes, BoardTypes, IBCTBoard } from 'layouts/pages/bct/types';

export interface Post {
  body: string;
  id: number;
  title: string;
  userId: number;
}

export interface IAuth0User {
  // TODO do we need the app to know more about the user from Auth0?
  email: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': Array<string>;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname': string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname': string;
}

export interface IActivityValidatorBlueprint {
  activityTypeGroups: ActivityType[][];
  activityTypesWithLearnerCreditValidation: string[];
  amaCreditTerm?: ITaxonomyTerm;
  pharmacyCreditTerm?: ITaxonomyTerm;
  boardLearnersReportedDictionary?: IDictionary<boolean>;
  boardMocDetails?: IDictionary<BoardMocDetails>;
  configuredBoards: IBCTBoard[];
  creditTypesDictionary: IDictionary<ITaxonomyTerm>;
  hasLearners?: boolean;
  learnerEarliestCompletion?: Date;
  learnerLatestCompletion?: Date;
  learnerMaximumCreditAmounts?: IDictionary<number>;
  maximumCertifyingBoardCredits?: IDictionary<number>;
  orgId: string;
  remsLearnersReported?: boolean;
  selectedActivityType?: string;
  status: StatusEnum;
}

export interface IAccordionItem {
  title: string;
  content: JSX.Element | string;
}

export interface IDictionary<TValue> {
  [key: string]: TValue;
}

export interface CommercialSupportSource {
  source: string;
  amountGiven?: number;
  hasInKindSupport?: boolean;
  id?: string; // Optional id used for rendering unique components in an array
}

export interface IContentOutline {
  id: string;
  otherText?: string;
}

export interface BoardMocDetails {
  practiceIds?: Array<string>;
  typesOfCreditIds?: Array<string>;
  mocPointsGiven?: number;
  contentOutlines?: Array<IContentOutline>;
}

export interface IBoardRemsDetails {
  additionalQuestionAnswers?: IDictionary<string>;
  isAttested?: boolean;
  remsActivityId?: string;
}

export enum ActivityLocationType {
  None = 0,
  InPerson = 1,
  Online = 2,
  Other = 4,
  OnlineOther = 6,
  Livestreamed = 8,
  InPersonLivestreamed = 9,
}

export enum LocationTypeStringEnum {
  None = 'None',
  InPerson = 'In-Person',
  Online = 'Online',
  Other = 'Other',
  Livestreamed = 'Live-Streamed',
}

export interface IActivityCreditTypes {
  credits: number;
  creditTypeId?: string;
  name?: string;
}

export interface ICreditTypes extends IActivityCreditTypes {
  abbreviation?: string;
  boardId?: string;
}

export interface IActivityCompletionBoard {
  count: number;
  name: string;
}

export enum HasPharmacyContentTags {
  No = 'No',
  Yes = 'Yes',
  NotSure = 'NotSure',
}

export enum HasStateContentTags {
  No = 'No',
  Yes = 'Yes',
}

export enum HasPharmacyRecertifications {
  No = 'No',
  Yes = 'Yes',
  NotSure = 'NotSure',
}

export enum HasPharmRecertTemplateOptions {
  yesPharmCert1 = 'yesPharmCert1',
  noPharmCert0 = 'noPharmCert0',
  notSurePharmCert2 = 'notSurePharmCert2',
}

export interface BaseActivity {
  activityId?: string;
  boards?: IBoard[];
  boardMocDetails?: IDictionary<BoardMocDetails>;
  boardRemsDetails?: IDictionary<IBoardRemsDetails>;
  businessId?: string;
  city?: string;
  closeDate?: Date;
  commendationCriteriaIds?: Array<string>;
  commercialSupportSources?: Array<CommercialSupportSource>;
  completionBoards?: IActivityCompletionBoard[];
  country?: string;
  credits?: IDictionary<number>;
  creditTypes?: ICreditTypes[];
  description?: string;
  detailsUrl?: string;
  endDate: Date;
  hasCommercialSupport?: boolean;
  hasLearners?: boolean;
  includeInCmeFinder?: boolean;
  internalId?: string;
  isDeleted?: boolean;
  isIpce?: boolean;
  isJointlyProvided?: boolean;
  isMips?: boolean;
  isMoc?: boolean;
  isPharmacyCertificateProgram?: boolean;
  isRems?: boolean;
  isRemsCompliant?: boolean;
  isRestrictedAudience?: boolean;
  isSelectedForReview?: boolean;
  jointProviders?: Array<string>;
  key?: string;
  learnerCounts?: IDictionary<number>;
  locationType?: string[];
  markedForCommendationCriteria?: boolean;
  mocCreditDeadline?: Date;
  mocProgramAttestation?: boolean;
  outcomesMeasured?: Array<string>;
  participationFeeTypeId?: string;
  providership?: string;
  pharmacyLiveDates?: Date[];
  pharmacySequenceNumber?: number;
  pharmacyTopicId?: string; // Taxonomy driven.
  pharmacyTypeId?: string; // Taxonomy driven.
  postalCode?: string;
  programs?: Array<string>; // Un-used by BE.
  recurring?: boolean;
  remsRpcId?: string;
  startDate: Date;
  stateOrProvince?: string;
  status?: StatusEnum;
  supportedRemsIds?: Array<string>;
  targetAudience?: string[];
  title: string;
  totalLearners?: number;
  type?: string;
  typeId: string;
  uanPharmacy?: string;
  uanPharmacyTech?: string;
  hasIndividualLearners?: boolean;
  hasRemsLearnersReported?: boolean;
  learnerEarliestCompletionDate?: Date;
  learnerLatestCompletionDate?: Date;
  learnerMaximumCreditAmounts?: IDictionary<number>;
  maximumCertifyingBoardCredits?: IDictionary<number>;
  maximumCertifyingBoardCreditTypeCredits?: IDictionary<IDictionary<number>>;
  hasPharmacyRecertifications?: HasPharmacyRecertifications;
  hasPharmacyContentTags?: HasPharmacyContentTags;
  hasStateContentTags?: HasStateContentTags;
  contentTags?: string[];
  pharmacyContentTagTaxonomyTerms?: string[];
  pharmacyContentTagStateTaxonomyTerms?: string[];
  pharmacyRecertificationTaxonomyTerms?: string[];
  isIndividualActivity?: boolean;
  iaOrganizationName?: string;
}

export interface Activity extends BaseActivity {
  createdDate?: Date;
  cmeCreditTypeTotal?: number;
  contentTags?: string[];
  creditClaimDate?: Date;
  id: string;
  moCCreditTypes?: ICreditTypes[];
  moCCreditTypeTotal?: number;
  printableId: string;
  pharmacyContentTagTaxonomyTerms?: string[];
  pharmacyContentTagStateTaxonomyTerms?: string[];
  providerName: string;
  rollupOrganizationEnum?: RollupOrganizationEnums;
  providerAutoCloseActivities?: boolean;
  isUnderReview?: boolean;
  hasReviewHistory?: boolean;
  hasPharmacyRecertifications?: HasPharmacyRecertifications;
  hasPharmacyContentTags?: HasPharmacyContentTags;
  hasStateContentTags?: HasStateContentTags;
  statusYear?: number;
}

export interface IdName {
  id: string;
  name: string;
}

export interface ActivitySearchResultActivity
  extends Omit<Activity, 'learnerCounts' | 'boardRemsDetails' | 'hasCommercialSupport'> {
  // Get-Activity is IDictionary<number>, search is array.  https://dev.azure.com/ACCMEdev/PARS%203.0/_workitems/edit/4317 to split the types
  learnerCounts: IActivityCompletionBoard[];
  remsDetails: IdName[];
  isSelectedForReviewStaffOrAccreditor?: boolean;
  isCommercialSupport?: boolean;
}

export interface INextSequenceNumber {
  nextSequenceNumber: number;
}

export type CreateActivityRequest = BaseActivity;

export interface UpdateActivityRequest extends BaseActivity {
  id: string;
}

export interface IBatchActivityRequest {
  file: { file: File };
  shouldForce?: boolean;
  rollupOrganizationEnum?: RollupOrganizationEnums;
}

export interface IActivityReviewHistoryRequest {
  reviewTypeId: string;
  reviewDate: Date;
  providerVisibleReviewPeriodStartDate: Date;
  providerVisibleReviewPeriodEndDate: Date;
  activityIds: string[];
}

export interface IActivityReviewOutcomeRequest {
  outcomeId: string;
  outcomeDate: Date;
  outcomeDetails: string;
}

export interface IActivityReviewHistory {
  canHaveOutcome: boolean;
  id: string;
  isMissingRequiredOutcome: boolean;
  isOngoing: boolean;
  reviewTypeId: string;
  reviewDate: Date;
  reviewNote: string;
  outcomeId: string;
  outcomeDate: Date;
  outcomeDetails: string;
  providerVisibleReviewPeriodStartDate: Date;
  providerVisibleReviewPeriodEndDate: Date;
}

export interface IQueueMocActivityRequest {
  activityLegacyId?: number;
  boardAbbreviation: string;
  action: string;
  deleteExisting: boolean;
  debug: boolean;
}

export interface IAdminDeleteActivityRequest {
  activityLegacyId: number;
}

export interface IActivitySearchRequest {
  activityTypes?: string[];
  boards?: string[];
  cities?: string[];
  collaborations?: string[];
  creditTypes?: string[];
  endDate?: Date;
  excludeFilters?: boolean;
  isCmeFinder?: boolean;
  isCommendation?: boolean;
  isCommercialSupport?: boolean;
  isDeleted?: boolean;
  isMips?: boolean;
  isIpce?: boolean;
  isMoc?: boolean;
  isPharmacyCertificateProgram?: boolean;
  isRems?: boolean;
  isSelectedForReview?: boolean;
  jointProviders?: string[];
  keys?: string[];
  learningMethods?: string[];
  learningMethod?: string[];
  measuredOutcomes?: string[];
  participationFees?: string[];
  participationFee?: string[];
  providership?: string[];
  providerships?: string[];
  registration?: string[];
  registrations?: string[];
  searchText?: string;
  skip?: number;
  sortAscending?: boolean;
  sortBy?: string;
  startDate?: Date;
  states?: string[];
  statuses?: string[];
  type?: string[]; // Called `Formats` in the facet
  top?: number;
}

export type IActivityLearnerSearchRequest = IActivitySearchRequest;

export interface IActivityLearnerSummarySearch extends IInitialSearchFacet {
  activityEndDate?: Date;
  activityGuid?: string;
  activityFormats?: string[];
  activityStartDate?: Date;
  activityStatuses?: string[];
  boards?: string[];
  cities?: string[];
  completionId?: string;
  completionInputMethods?: string[];
  completionStatuses?: string[];
  creditTypes?: string[];
  isCmeFinder?: boolean;
  isCommercialSupport?: boolean;
  isMips?: boolean;
  isMoc?: boolean;
  isRems?: boolean;
  learnerCompletionEndDate?: Date;
  learnerCompletionStartDate?: Date;
  learnerEntryMethods?: string[];
  learnerStatuses?: string[];
  learnerSubmissionEndDate?: Date;
  learnerSubmissionStartDate?: Date;
  measuredOutcomes?: string[];
  participationFees?: string[];
  providerIds?: string[];
  providerNames?: string[];
  providership?: string[];
  states?: string[];
  ulid?: string;
}

type ActivityFormUnion =
  | 'boardMocDetails'
  | 'boardRemsDetails'
  | 'city'
  | 'country'
  | 'description'
  | 'detailsUrl'
  | 'internalId'
  | 'mocCreditDeadline'
  | 'pharmacyLiveDates'
  | 'pharmacySequenceNumber'
  | 'pharmacyTopicId'
  | 'pharmacyTypeId'
  | 'postalCode'
  | 'remsRpcId'
  | 'stateOrProvince'
  | 'supportedRemsIds'
  | 'title';

export interface AddActivityFormValues extends Pick<BaseActivity, ActivityFormUnion> {
  boardMocDetailsSelection?: string[];
  commercialSupportSourceKind?: string[];
  credits?: IDictionary<boolean | number>;
  contentTags?: string[];
  hasCommercialSupport?: any;
  hasPharmacyRecertifications?: any;
  hasPharmacyContentTags?: any;
  hasStateContentTags?: any;
  endDate?: Date;
  includeInCmeFinder?: any;
  internalId?: string;
  isJointlyProvided?: any;
  isMips?: any;
  isMoc?: any;
  isPharmacyCertificateProgram?: any;
  isTargetAudiencePharmacy?: any;
  isTargetAudiencePharmacyTech?: any;
  isRems?: any;
  isIpce?: any;
  isRemsCompliant?: any;
  isRestrictedAudience?: any;
  locationType?: string[];
  recurring?: any;
  targetAudience?: string[];
  markedForCommendationCriteria?: any;
  mocProgramAttestation?: any;
  pharmacyContentTagStateTaxonomyTerms?: string[];
  pharmacyContentTagTaxonomyTerms?: string[];
  pharmacyRecertificationTaxonomyTerms?: string[];
  startDate?: Date;
  supportedRemsIds?: string[];
  typeId?: string;
  isIndividualActivity?: boolean;
  iaOrganizationName?: string;
}

// Customized CommercialSupportSource interface for working within Typeahead limitations
export interface IMonetarySupportSourceOption {
  source: string;
  amountGiven?: number;
  id: string;
}

export type IInKindSupportSourceOption = string;

export type ICommercialSupportSourceOption = IMonetarySupportSourceOption | IInKindSupportSourceOption;

export interface ActivityFormStateValues extends AddActivityFormValues {
  commercialSupportSources?: ICommercialSupportSourceOption[];
  commercialMonetarySupportSources?: IMonetarySupportSourceOption[];
  commercialInKindSupportSources?: IInKindSupportSourceOption[];
  jointProviders?: string[];
  targetAudience?: string[];
  pharmacyLiveDates?: Date[];
  tempDatesAndLocation?: IDatesAndLocation[];
}

export interface IDatesAndLocation {
  city?: string;
  country?: string;
  endDate: Date;
  postalCode?: string;
  startDate: Date;
  stateOrProvince?: string;
}

/**
 * Facet Search
 */
export interface IFacetSearch {
  facetSearchText: string;
}

export interface IFacetSearchSuccess {
  totalCount: number;
  facets: Facet;
}

export interface IFacetSearchError {}

// Needs updating.
export interface Facet {
  'JointProviders/Name'?: string[];
  Boards?: string[];
  City?: string[];
  Country: string[];
  IsCMEFinder?: boolean[];
  IsCommendation?: boolean[];
  IsCommercialSupport?: boolean[];
  JointProviders?: string[];
  LearningMethod?: string[];
  MeasuredOutcomes?: string[];
  ParticipationFee?: string[];
  Providership?: string[];
  Registration?: string[];
  State?: string[];
  Status?: string[];
  Type?: string[];
  IsIndividualActivity: boolean;
  IaOrganizationName: string[];
}

export interface IActivitySearchFacets {
  boards: string[];
  city: string[];
  isCmeFinder: boolean[];
  isCommendation: boolean[];
  isCommercialSupport: boolean[];
  isMips: boolean;
  isMoc: boolean;
  isIpce: boolean;
  isRems: boolean;
  jointProviders: string[];
  learningMethod: string[];
  measuredOutcomes: string[];
  participationFee: string[];
  providership: string[];
  registration: string[];
  state: string[];
  status: string[];
  type: string[];
}

export interface ILearnerActivity {
  activityEndDate?: string;
  activityFormat?: string;
  activityStartDate?: string;
  activityStatus?: string;
  activityCreditTypes?: IActivityCreditTypes[];
  boards?: Pick<IBCTBoard, 'abbreviation' | 'name'>[];
  businessId?: string;
  claimDate?: string;
  city?: string;
  creditTypes?: ICreditTypes[];
  id: string;
  internalId?: string;
  isMips: boolean;
  isMoc: boolean;
  isRems: boolean;
  key?: string;
  learnerCount?: number;
  providerId: string;
  providerName?: string;
  state?: string;
  statusCountsByBoard?: ILearnerActivityStatusCount[];
  timestamp?: Date;
  title?: string;
}

export interface ILearnerActivityStatusCount {
  boardId?: string;
  count?: number;
  status?: 'Accepted' | 'Submitted' | 'Rejected';
}

/**
 * Learner Summary
 */

export interface IActivityLearnerSummarySearchResult {
  score: number;
  highlights: any;
  document: ILearnerActivity;
}

export interface IActivitySearchResponse {
  totalCount: number;
  facets: Facet;
  results: IActivitySearchResult[];
}

/**
 * Learner Search
 */

export interface IActivityLearnerSearchResult {
  score: number;
  highlights: any;
  document: ILearnerSearchActivity;
}

export interface IActivitySearchResult {
  score: number;
  highlights: any;
  document: ActivitySearchResultActivity;
}

export interface IActivityLearnerSummarySearchResponse {
  totalCount: number;
  facets: Facet;
  results: IActivityLearnerSummarySearchResult[];
}

export interface IActivityLearnerSearchResponse {
  totalCount: number;
  facets: Facet;
  results: IActivityLearnerSearchResult[];
}

export interface IActivitySearchStateProps {
  page: number;
  selectedActivities?: ActivitySearchResultActivity[];
}

export interface IActivityLearnerCompletion {
  completionId: string;
  remsId?: string;
  boardId?: string;
  stateAbbreviation?: string;
  completionSource?: string;
  completionIdentifier?: string;
}

export interface IBulkActivityLearnerCompletions {
  activityId: string;
  payload: IActivityLearnerCompletion[];
}

export interface IActivityLearnerSearchStateProps {
  page: number;
  selectedLearners?: ILearnerSearchActivity[];
}

export type pharmacyAbbreviation = 'L' | 'H';

export type CompletionSource = 'Board' | 'REMS' | 'State';

export enum CompletionSourceEnum {
  BOARD = 'Board',
  REMS = 'REMS',
  STATE = 'State',
}

export interface ActivityType {
  abbreviation?: ACTIVITY_TYPE_ABBREVIATION_ENUM;
  pharmacyAbbreviation?: pharmacyAbbreviation;
  description: string;
  id: string;
  title: string;
  typeId: string;
  displayOrder: number;
}

export interface ContentTag {
  id: string;
  tooltip: string;
  contentTagName: string;
  parentContentTagId?: string | null;
  childContentTags?: ContentTag[];
  parentContentTag?: ContentTag | null;
}

export interface IProcessActivityRequest {
  activityLegacyId?: number;
  boardAbbreviation: string;
  action: string;
  transferStatusCode: string;
  errorCode: string;
  rejectedReason: string;
  debug: boolean;
}

export interface IAdminCommandResponse {
  error?: {
    errorCode: number;
    message: string;
  };
  recordsAffected: number;
  recordSets: unknown[];
}

export interface IArrayBufferDownload {
  file?: ArrayBuffer;
  type: string;
}

export interface IFileDownload {
  file?: Blob;
  type?: string;
  disposition?: string;
  filename?: string;
}

export type ManualDownloadLink = Omit<IManualDownloadLinkProps, 'children'>;

export enum LearnerLocationType {
  None = 0,
  InPerson = 1,
  Online = 2,
  Other = 4,
}

export interface BaseLearner {
  boardMocDetails?: IDictionary<BoardMocDetails>;
  city?: string;
  commendationCriteriaIds?: Array<string>;
  commercialSupportSources?: Array<CommercialSupportSource>;
  country?: string;
  credits?: IDictionary<number>;
  description?: boolean;
  detailsUrl?: string;
  endDate: Date;
  hasCommercialSupport?: boolean;
  includeInCmeFinder?: boolean;
  internalId?: string;
  isJointlyProvided?: boolean;
  isMips?: boolean;
  isMoc?: boolean;
  isRems?: boolean;
  isRemsCompliant?: boolean;
  isRestrictedAudience?: boolean;
  jointProviders?: Array<string>;
  learnerCounts?: IDictionary<number>;
  locationType?: LearnerLocationType;
  markedForCommendationCriteria?: boolean;
  mocCreditDeadline?: Date;
  mocProgramAttestation?: boolean;
  outcomesMeasured?: Array<string>;
  participationFeeTypeId?: string;
  postalCode?: string;
  remsRpcId?: string;
  startDate: Date;
  stateOrProvince?: string;
  supportedRemsIds?: Array<string>;
  title: string;
  typeId: string;
}

export interface Learner extends BaseLearner {
  id: string;
  printableId: string;
}

export type ILearnerSearchRequest = IActivityLearnerSummarySearch;

export interface ILearnerExportRequest extends ILearnerSearchRequest {
  activityId?: string;
}

export type CreateLearnerRequest = BaseLearner;

export interface UpdateLearnerRequest extends BaseLearner {
  id: string;
}

export interface IBatchLearnerRequest {
  file: { file: File };
  shouldForce?: boolean;
}

export interface LearnerType {
  id: string;
  title: string;
  typeId: string;
}

// Used by EditCompletion form
export enum LearnerCreditsBoardTypeEnum {
  MOC = 'creditsByCertifyingBoard',
  REMS = 'creditsByRems',
  STATE = 'creditsByStateOrProvince',
}

interface IEditCompletionBoardModel {
  deaRegistrationTypeId?: string;
  learnerCredits?: IDictionary<ILearnerCredits>;
  learnerId?: string;
  performsSurgicalProcedures?: boolean;
  practiceAreaId?: string;
  practiceStateOrProvince?: ITypeaheadOption | string;
  prescribedInPast12Months?: boolean;
  professionId?: string;
  timeInPracticeId?: string;
  totalCredits?: number;
}

export interface IEditCompletionFormModel {
  completionDate: string;
  creditsByCertifyingBoard: IDictionary<IEditCompletionBoardModel>;
  creditsByRems: IDictionary<IEditCompletionBoardModel>;
  creditsByStateOrProvince: IDictionary<IEditCompletionBoardModel>;
}

export interface ILearnerCredits {
  credits?: number;
  providerCreditId?: string;
  selected?: boolean;
}

export interface ICompletionBoard {
  createdDate?: string;
  deaRegistrationTypeId?: string;
  id?: string;
  learnerCredits?: IDictionary<ILearnerCredits>;
  learnerId?: string;
  licenseId?: string;
  performsSurgicalProcedures?: boolean;
  practiceAreaId?: string;
  practiceStateOrProvince?: string;
  prescribedInPast12Months?: boolean;
  professionId?: string;
  timeInPracticeId?: string;
  totalCredits?: number;
  transferStatus?: string;
  updatedDate?: string;
}

export interface ILearnerCompletion {
  completionDate?: string;
  createdDate?: string;
  creditsByCertifyingBoard?: IDictionary<ICompletionBoard>;
  creditsByStateOrProvince?: IDictionary<ICompletionBoard>;
  creditsByRems?: IDictionary<ICompletionBoard>;
  dateOfBirthDay?: number;
  dateOfBirthMonth?: number;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  medicalSchoolId?: string;
  medicalSchoolGraduationYear?: number;
  npi?: string;
  universalLearnerId?: string;
}

export type ICompletionRecord = Pick<
  ILearnerSearchActivity,
  | 'activityName'
  | 'board'
  | 'boardName'
  | 'completionDate'
  | 'credits'
  | 'learnerDOBDay'
  | 'learnerDOBMonth'
  | 'learnerName'
  | 'route'
  | 'totalCredits'
>;

export interface ILearnerMatchingModel {
  birthDay: number;
  birthMonth: number;
  boardIds?: IDictionary<string>;
  firstName: string;
  includeCme?: boolean;
  lastName: string;
  licenseId?: string;
  medicalSchoolName?: string;
  npi?: string;
  stateName?: string;
}

interface ILearnerMatchResponseField {
  name: string | number;
}

export type LearnerIdMatchSource =
  | 'CertifyingBoardCompletion'
  | 'CertifyingBoardProfile'
  | 'DiplomateBoard'
  | 'DiplomateState'
  | 'FSMB'
  | 'StateBoardCompletion'
  | 'StateBoardProfile';

export interface ILearnerIdMatchField {
  abbreviation?: string;
  boardId?: string;
  certifyingBoard?: string;
  expirationDate?: string;
  learnerId: string;
  name: string;
  source: LearnerIdMatchSource;
}

export interface ILearnerMatchResponse {
  dobDates: ILearnerMatchResponseField[];
  dobMonths: ILearnerMatchResponseField[];
  firstNames: ILearnerMatchResponseField[];
  isDeleted: boolean;
  key: string;
  lastNames: ILearnerMatchResponseField[];
  learnerIds: ILearnerIdMatchField[];
  npis: ILearnerMatchResponseField[];
  medicalSchools: ILearnerMatchResponseField[];
}

// Transformed Learner Match Response
export interface IMatchedLearner {
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  learnerMocBoards: ILearnerIdMatchField[];
  learnerRems?: ILearnerIdMatchField[];
  learnerStateBoards: ILearnerIdMatchField[];
  medicalSchoolName: string;
  npi: string;
  ulid: string;
}

export interface ICompletionValidationCredits {
  defaultCredits?: string;
  dependencies?: string[];
  maxCredits?: number;
}

export interface ICountry {
  countryName: string;
  isoCountryCode: string;
}

export interface ILearnerCompletionValidationBlueprint {
  boardActivityDetails?: IDictionary<BoardMocDetails>;
  boardConfigs: IDictionary<IBCTBoard>;
  creditMaxClaimDate?: Date;
  endDate: Date;
  isLearnerMatched: boolean;
  isMaxCreditsLimited?: boolean;
  isMoc: boolean;
  maxTotalCredits?: number;
  startDate: Date;
  amaCreditTerm?: ITaxonomyTerm;
}

export interface IEditLearnerCompletionValidationBlueprint
  extends Omit<ILearnerCompletionValidationBlueprint, 'endDate'> {
  boardId: string;
  boardType: LearnerCreditsBoardTypeEnum;
  isState: boolean;
}

// TODO: Replace with up-to-date schema using BCT integration
/** @deprecated */
export interface ICompletionValidationBlueprint {
  boardCredits?: IDictionary<ICompletionValidationCredits>;
  // TODO: BCT adds creditIncrement; used for validating credits
  creditMaxClaimDate?: Date;
  isMoc: boolean;
  isState: boolean;
  maxTotalCredits?: number;
  startDate?: Date;
}

export interface ICertifyingBoardFormModel {
  boardId?: string;
  certifyingBoard?: string;
  deaRegistration?: string;
  id?: string; // Generated ID via uniqueId for maintaining keys in React
  learnerCredits?: IDictionary<number>; // TODO: Might be able to prepopulate this based on board credit types
  practiceArea?: string;
  practiceState?: string;
  prescribedInPast12Months?: boolean;
  profession?: string;
  providerLearnerId?: string;
  surgicalProcedures?: boolean;
  timeInPractice?: string;
  totalCredits?: number;
}

export enum CollaborationTypes {
  CREDITS_BY_CERTIFYING_BOARD = 'CreditsByCertifyingBoard',
  CREDITS_BY_REMS = 'CreditsByRems',
  CREDITS_BY_STATE_OR_PROVINCE = 'CreditsByStateOrProvince',
}

type CollaborationType =
  | CollaborationTypes.CREDITS_BY_CERTIFYING_BOARD
  | CollaborationTypes.CREDITS_BY_REMS
  | CollaborationTypes.CREDITS_BY_STATE_OR_PROVINCE;

export interface IBoardCredits {
  abbreviation?: string;
  credits?: number;
  creditTypeId?: string;
  mocName?: string; // only on MOC
  name?: string;
}

export interface ILearnerSearchActivity {
  activityGuid?: string;
  activityId?: string;
  activityName?: string;
  board?: string;
  boardName?: string;
  businessId?: string;
  collaborationType?: CollaborationType;
  completionDate?: Date;
  submissionDate?: Date;
  completionSource?: CompletionSource;
  completionId?: string;
  completionIdentifier?: string;
  credits?: IBoardCredits[];
  deaRegistrationType?: string;
  firstName?: string;
  key?: string;
  lastName?: string;
  learnerDOBDay?: number;
  learnerDOBMonth?: number;
  learnerId?: string;
  learnerName?: string;
  learnerStatus?: StatusEnum;
  performsSurgicalProcedures?: boolean;
  practiceArea?: string;
  practiceStateOrProvince?: string;
  prescribedInPast12Months?: boolean;
  profession?: string;
  providerName?: string;
  rejectionReason?: string;
  route?: string;
  rems?: string;
  remsName?: string;
  state?: string;
  stateAbbreviation?: string;
  stateName?: string;
  timeInPractice?: string;
  totalCredits?: number;
  ulid?: string;
}

export interface IAddLearnersFormModel {
  certifyingBoards?: ICertifyingBoardFormModel[];
  credits?: IDictionary<number | boolean>; // 'true'/'false' for checkbox; number for credit value
  completionDate?: Date;
  dateOfBirth?: Date;
  firstName?: string;
  lastName?: string;
  licenseId?: string;
  medicalSchoolOption?: ITypeaheadOption;
  npi?: string;
  stateName?: ITypeaheadOption;
}

export interface IValidateLearnersToolFormModel {
  dateOfBirth?: Date;
  firstName?: string;
  lastName?: string;
  licenseId?: string;
  medicalSchoolName?: ITypeaheadOption;
  npi?: string;
  stateName?: ITypeaheadOption;
  boardName?: ITypeaheadOption;
  boardId?: string;
}

export interface ILearnerCreditDetails {
  boardAbbr?: string;
  credits?: IDictionary<number>;
  totalCredits?: number;
}

export interface IProviderFileBase {
  createdDate: Date;
  date?: string;
  fileExtension?: string;
  fileName: string;
  fileSize?: number;
  id: string;
  providerFileType: ProviderFileType;
  updatedDate: Date;
  recordsInFile?: number;
  recordsParsed?: number;
  recordsProcessed?: number;
  lastStatusMessage?: string;
}

export interface IProviderFile extends IProviderFileBase {
  bulkUploadResult: string;
  childProviderFileIds?: string[];
  children: IProviderFileChild[];
  parentProviderFileId?: string;
  recordsAdded: number;
  recordsDeleted: number;
  recordsRejected: number;
  recordsUpdated: number;
}

export interface ILeaernerValidationProviderFile extends IProviderFileBase {
  relatedProviderFiles: IProviderFileChild[];
  blobFileName: string;
}

export interface IProviderFileChild {
  bulkUploadResult: string;
  createdDate: Date;
  fileExtension: string;
  fileName: string;
  id: string;
  providerFileType: string;
  updatedDate: Date;
}

export type ProviderFileType = 'BulkActivities' | 'BulkLearners';

export interface PARSAction<TPayload = any, TAction = string> extends Action<TAction> {
  readonly type: TAction;
  readonly payload?: TPayload;
  readonly error?: boolean;
  readonly meta?: any;
}

export interface IToastOptions {
  autoHideDuration?: number;
  centered?: boolean;
  disabled?: boolean;
  dismissable?: boolean;
  icon?: ReactElement;
  location?: ToastLocationEnum;
  message?: ReactElement;
  toastType: ToastType;
}

export interface IStaticToastOptions extends IToastOptions {
  onClick?: () => void;
  disabledText?: string;
}

export interface ITaxonomyTermSlim {
  id: string;
  terms: ITaxonomyTermSlim[];
}

export interface ITaxonomyTerm {
  name: string;
  description?: string;
  id: string;
  endDate: Date;
  startDate: Date;
  metadataText: string;
  metadataNumber?: number;
  metadataNumber2?: number;
  parentId: string;
  sortOrder: number;
  tag?: TAGS_ENUM;
  terms: ITaxonomyTerm[];
}

export interface IStateAndProvince {
  isoCountryCode: string;
  isoStateCode: string;
  stateName: string;
}

export type ITaxonomyDictionary = { [id: string]: ITaxonomyTerm };

export declare type FormInputType =
  | 'Boolean'
  | 'Choice'
  | 'Date'
  | 'DateTime'
  | 'Guid'
  | 'Integer'
  | 'MocBoardRepeater'
  | 'MultiChoice'
  | 'MultiChoiceGrid'
  | 'NestedMultiChoice'
  | 'MultiChoiceBoolean'
  | 'MultiChoicePill'
  | 'MultiChoiceWithNumber'
  | 'MultiTextDictionary'
  | 'Note'
  | 'Number'
  | 'RemsRepeater'
  | 'RepeatDate'
  | 'RepeatInput'
  | 'RepeatMultiTypeahead'
  | 'RepeatTypeahead'
  | 'Taxonomy'
  | 'TaxonomyMulti'
  | 'Text'
  | 'Time'
  | 'Typeahead';

export declare type ChoiceFormatType = 'Dropdown' | 'Radiobuttons';

export interface IOperand {
  ['$type']: string;
  OperandType: 'Comparison' | 'Constant' | 'FieldRef' | 'Logical';
}

export interface IBinaryOperation extends IOperand {
  Left: IOperand;
  Right: IOperand;
}

export interface IComparisonOperation extends IBinaryOperation {
  ComparisonOperationType: 'Eq' | 'Geq' | 'Gt' | 'IsNotNull' | 'IsNull' | 'Leq' | 'Lt' | 'Neq';
}

export interface IConstantOperation extends IOperand {
  FormInputType: FormInputType;
  Value: string | number | boolean | Date | Array<string> | null;
}

export interface IFieldRefOperation extends IOperand {
  FormInputType: FormInputType;
  JsonPath: string;
}

export interface ILogicalOperation extends IBinaryOperation {
  LogicalOperationType: 'And' | 'Or';
}

export interface IWhere {
  Operand: IOperand;
}

export interface IFormItem {
  ['$type']: string;
  Id: string;
  Visible: IWhere;
  Title?: string;
  ModalTitle?: string;
  ModalSubtitle?: string;
  ModalContent?: Array<IModalContentItem>;
  InputCaption?: string;
  LabelCaption?: string;
  TooltipContent?: string;
  FilterByOrganization?: boolean;
}

export interface IFormGroup extends IFormItem {
  Children: Array<IFormItem>;
  Variant: string;
  IsRequired: string;
  HideGroupTitleStyle?: boolean;
}

export interface IFormSection extends IFormGroup {
  Breadcrumb: string;
  Title: string;
  Subtitle: string;
}

export interface IFormInputOption {
  Id: string;
  Title: string;
  Description?: string;
  isDefault?: boolean;
  isDisabled?: boolean;
  LabelCaption?: string;
  InputCaption?: string;
  terms?: ITaxonomyTerm[];
  Watermark?: string;
  tag?: string;
  group?: string;
  noValue?: boolean;
  sortOrder?: number;
  Tooltip?: string;
}

export interface IModalContentItem {
  Title: string;
  Content: string;
}

export interface IValidationException {
  errorCode: string;
  message: string;
}

export type CreditTypesErrors = Record<string, { validationExceptions: IValidationException[] }>;

export interface IErrorAndValidationExceptions {
  creditTypesErrors?: CreditTypesErrors;
  error: Error;
  validationExceptions: IValidationException[];
}

export interface IFormInput extends IFormItem {
  ChoiceFormatType?: ChoiceFormatType;
  Description: string;
  HideErrorContent?: boolean;
  HideGroupTitleStyle?: boolean;
  Max: number;
  Min: number;
  Options?: Array<IFormInputOption>;
  RepeatButtonText: string;
  Required: IWhere;
  TermSetId?: string;
  Title: string;
  TitleTwo?: string;
  TooltipContent: string;
  Type: FormInputType;
  UseIntermediateTerms?: boolean;
  ValueJsonPath: string;
  Variant: string;
  Watermark: string;
  WatermarkTwo?: string;
}

export interface IFormCheckbox {
  isRequired?: boolean;
  labelCaption?: string;
  formikKey?: string;
  title?: string;
}

/**
 * @deprecated replace with IBCTBoard
 */
export interface IBoard {
  id: string;
  abbreviation: string;
  name: string;
  contentOutlines: Array<ITaxonomyTerm>;
  creditTypes: Array<ITaxonomyTerm>;
  specialties: Array<ITaxonomyTerm>;
}

export interface IBoardActivityType {
  allowRepeatParticipation: boolean;
  id: string;
  maxCreditValidation: boolean;
  startDate: Date;
  endDate: Date;
}

export interface IBoardAdditionalFields {
  id: string;
  question: string;
}

export interface IRemsRegex {
  errorMessage?: string;
  label?: string;
  regularExpression?: string;
}

export interface IBoardCreditType {
  id: string;
  activityStartDate?: Date;
  activityEndDate?: Date;
}

export interface IBCTSpecialty {
  id?: string;
  name: string;
  sortOrder: number;
}

export interface IBCTLearnerDate {
  cutoffDate: Date;
  year: number;
}

export interface IBoardSlim {
  abbreviation: string;
  activityStatus?: string;
  boardActivityTypes?: IBoardActivityType[];
  id: string;
  learnerStatus?: string;
  name: string;
  type: BoardTypes;
  boardIntegrationType: BoardIntegrationTypes;
  providerActivityIdRequired: boolean;
  boardRemsIdRegularExpressions?: IRemsRegex;
  creditTypes?: IBoardCreditType[];
  specialties?: IBCTSpecialty[];
  boardLastLearnerCompletionRemovalDate?: IBCTLearnerDate[];
  boardLastLearnerCompletionDate?: IBCTLearnerDate[];
}

export interface IBulkActivityHeader {}

export interface IBulkLearnerHeader {}

//  https://docs.microsoft.com/en-us/aspnet/core/web-api/jsonpatch?view=aspnetcore-3.1#json-patch
//  https://tools.ietf.org/html/rfc6902.
export interface IJsonPatchDocumentOperation {
  path: string;
  op: string;
  from?: string;
  value?: any;
}

export enum OrganizationKinds {
  ACCREDITOR = 'Accreditor',
  BOARD = 'Board',
  PROVIDER = 'Provider',
  SPECIAL_ORGANIZATION = 'SpecialOrganization',
  STAFF = 'Staff',
}

export enum RollupOrganizationEnums {
  PARS = 'PARS',
  JA = 'JA',
  NARS = 'NARS',
}

export type OrganizationKind = 'Accreditor' | 'Board' | 'Provider' | 'SpecialOrganization' | 'Staff';

export interface IUserOrganization {
  id: string;
  businessId: string;
  organizationName: string;
  organizationKind: OrganizationKind;
  childOrganizationIds: string[];
  customerId?: string;
  rollupOrganizationEnum?: RollupOrganizationEnums;
  securityGroups?: string[];
  isPrimaryContact?: boolean;
  organizationNotes?: string;
  organizationStatus?: string;
  accreditorOrApprover?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressCountry?: string;
  addressZipCode?: string;
  phoneNumberCountryCode?: string;
  phoneNumberAreaCode?: string;
  phoneNumberLocal?: string;
  faxNumberCountryCode?: string;
  faxNumberAreaCode?: string;
  faxNumberLocal?: string;
  organizationType?: string;
  webAddress?: string;
  isApproverOfActivities?: boolean;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  defaultOrganizationId?: string;
  primaryUserOrganizations: IUserOrganization[];
  secondaryUserOrganizations: IUserOrganization[];
  staffRollupOrganizationEnums: RollupOrganizationEnums[];
  currentProvider: IUserOrganization;
  hasChildApproverOfActivities?: boolean;
}

export interface IProgramSummary {
  advertisingAndExhibitOverall?: number;
  registrationFees?: number;
  governmentGrants?: number;
  privateDonations?: number;
  isDraft?: boolean;
  updatedDate?: Date;
  email: string;
}

export interface IGetAttestationResponse {
  heading: string;
  subHeading: string;
  content: string;
  isAttested?: boolean;
  startDate: Date;
  endDate: Date;
  updatedById: string;
  updatedDate: Date;
  email: string;
}

export interface IAttestation {
  heading: string;
  subHeading: string;
  content: string;
  isAttested: boolean;
  startDate: Date;
  endDate: Date;
  updatedById: string;
  updatedDate: Date;
  email: string;
}

export interface IQueueMocLearnerRequest {
  lccbId: string;
  action: string;
  deleteExisting: boolean;
  ignoreBadMocStatus: boolean;
  debug: boolean;
}

export interface IProcessBoardCreditRequest {
  boardId: string;
  lccbId: string;
  status: string;
  errorCode: string;
  rejectedReason: string;
}

export interface IAdminDeleteLearnerChildCompletionRequest {
  childCompletionId: string;
}

// BCT
export interface IBCT {}

// Keep this order.
export enum BCTSteps {
  ORGANIZATION_DETAILS = 'Organization Details',
  ACTIVITY_REGISTRATION_DETAILS = 'Activity Registration Details',
  CONTENT_BLUEPRINT_DETAILS = 'Content Blueprint Details',
  CREDIT_TYPE_DETAILS = 'Credit Type Details',
  LEARNER_COMPLETION_DETAILS = 'Learner Completion Details',
}

export interface IDisabledState {
  isDisabled: boolean;
  disabledOptions?: string[]; // For disabling a specific set of options in a list
}

export type IDisabledContext = IDictionary<Record<string, unknown> | IDisabledState>;

export interface BoardProcessingMetrics {
  activitiesStatusRate: BoardProcessingMetricsStatusRate[];
  learnersStatusRate: BoardProcessingMetricsStatusRate[];
  activitiesNotSent: BoardProcessingMetricsNotSentActivity[];
  learnersNotSent: BoardProcessingMetricsNotSentLearner[];
  activitiesNotProcessed: BoardProcessingMetricsNoResultActivity[];
  learnersNotProcessed: BoardProcessingMetricsNoResultLearner[];
  activitiesPending: BoardProcessingMetricsNoResultActivity[];
  learnersPending: BoardProcessingMetricsNoResultLearner[];
  activitiesRejected: BoardProcessingMetricsRejectedActivity[];
  learnersRejected: BoardProcessingMetricsRejectedLearner[];
}

export interface BoardProcessingMetricsBase {
  boardAbbreviation: string;
  boardName: string;
}

export interface BoardProcessingMetricsActivityBase {
  oneActivityId: string;
}

export interface BoardProcessingMetricsLearnerBase {
  oneLccbId: string;
  transferStatus: string;
}

export interface BoardProcessingMetricsStatusRate extends BoardProcessingMetricsBase {
  date: string;
  accepted: number;
  rejected: number;
  notProcessed: number;
  pending: number;
  ignored: number;
  total: number;
  rejectPct: number;
  pendingPct: number;
}

export interface BoardProcessingMetricsNotSent extends BoardProcessingMetricsBase {
  reason: string;
  count: number;
  oldestUpdatedDate: Date;
}

export interface BoardProcessingMetricsNotSentActivity
  extends BoardProcessingMetricsNotSent,
    BoardProcessingMetricsActivityBase {}

export interface BoardProcessingMetricsNotSentLearner
  extends BoardProcessingMetricsNotSent,
    BoardProcessingMetricsLearnerBase {}

export interface BoardProcessingMetricsNoResult extends BoardProcessingMetricsBase {
  action: string;
  hoursSinceCreated: number;
  count: number;
}

export interface BoardProcessingMetricsNoResultActivity
  extends BoardProcessingMetricsNoResult,
    BoardProcessingMetricsActivityBase {}

export interface BoardProcessingMetricsNoResultLearner
  extends BoardProcessingMetricsNoResult,
    BoardProcessingMetricsLearnerBase {}

export interface BoardProcessingMetricsRejected extends BoardProcessingMetricsBase {
  action: string;
  errorCode: string;
  errorDescription: string;
  daysSinceQueueUpdated: number;
  count: number;
}

export interface BoardProcessingMetricsRejectedActivity
  extends BoardProcessingMetricsRejected,
    BoardProcessingMetricsActivityBase {}

export interface BoardProcessingMetricsRejectedLearner
  extends BoardProcessingMetricsRejected,
    BoardProcessingMetricsLearnerBase {}

export interface ProviderSubmissionLog {
  id: string;
  createdDate: Date;
  providerName: string;
  providerId: string;
  providerFileId: string;
  providerFileName: string;
  providerSubmissionType: 'Activity' | 'Learner';
  providerSubmissionChannel: ProviderSubmissionChannel;
  activitiesAdded?: number;
  activitiesUpdated?: number;
  activitiesDeleted?: number;
  activitiesRejected?: number;
  learnersSubmitted?: number;
  learnersMatched?: number;
  learnersNotMatched?: number;
}

export interface CommandParameter {
  name: string;
  type: CommandType;
  example: string;
}

export interface CommandInfo {
  commandName: string;
  parameters: CommandParameter[];
}

export interface CommandErrorInfo {
  errorCode?: number;
  message: string;
}

export interface CommmandInvokactionResult {
  error: CommandErrorInfo;
  recordsAffected: number;
  recordSets: Record<string, unknown>[][];
}

export interface BaseUser {
  organizationId: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export type CreateUserRequest = BaseUser;

export enum EnvironmentEnum {
  Production = 'Production',
  Sandbox = 'Sandbox',
  Development = 'Development',
  UA = 'UA',
}

export interface BaseOrganizationUser {
  firstName: string;
  middleName?: string;
  lastName: string;
  title?: string;
  position?: string;
  credentials?: string;
  useOrganizationAddress: boolean;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressZipCode?: string;
  addressCountry?: string;
  phoneCountryCode?: string;
  phoneAreaCode?: string;
  phoneLocal?: string;
  faxCountryCode?: string;
  faxAreaCode?: string;
  faxLocal?: string;
  isPrimaryContact: boolean;
  isStaff: boolean;
}

export interface OrganizationUser extends BaseOrganizationUser {
  userId: string;
  armaturePersonId: string;
  accreditorName: string;
  providerName: string;
  email: string;
}

export interface CreateOrganizationUserRequest extends BaseOrganizationUser {
  organizationId: string;
  email: string;
}

export interface UpdateOrganizationUserRequest extends BaseOrganizationUser {}

export interface SearchOrganizationUserRequest {
  organizationId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneCountryCode?: string;
  phoneAreaCode?: string;
  phoneLocal?: string;
  isPrimaryContact?: boolean;
}

export interface Accreditation {
  id?: string;
  accreditationTypeId: string;
  accreditationType?: string;
  progressReportDueDate?: Date;
  effectiveDate: Date;
  expirationDate: Date;
}

export interface IAccreditationType {
  id: string;
  description: string;
  rollupOrganizationCode: number;
}

export interface IOrganizationType {
  id: string;
  description: string;
  rollupOrganizationCode: number;
}

export interface IOrganizationStatus {
  id: string;
  name: string;
  rollupOrganizationCode: number;
}

export interface SearchOrganizationProviderRequest {
  organizationId: string;
  providerType: string;
  organizationStatusId: string;
  providerName: string;
  businessId: string;
  accreditationTypeId: string;
  accreditorApproverId: string;
  searchDeleted: string;
}

export interface SearchOrganizationProviderResponse {
  id: string;
  accreditorOrApproverName: string;
  organizationId: string;
  providerType: string;
  providerName: string;
  providerOrganizationID: string;
  organizationTypeName: string;
  organizationStatusName: string;
  accreditationTypeId: string;
  isDeleted: boolean;
  accreditationExpirationDate?: Date;
}

export interface IOrganizationProvider extends IUserOrganization {
  organizationName: string;
  organizationStatusId?: string;
  organizationTypeId?: string;
  providerType?: string;
  accreditorApproverId?: string;
  accreditorName: string;
  accreditorBusinessId: string;
}
